<template>
  <div class="classHour" v-loading="loading">
    <el-tabs v-model="activeName"  class="el-nav" @tab-click="handleClick">
      <div class="el-nav-header flex-nowrap">
        <img :src="courseInfo.pc_cover_picture" class="table-img" alt="">
        <div class="header-r">
          <div class="table-item-name">{{ courseInfo.title }}</div>
          <div class="table-item-times flex-nowrap">
            <div class="table-item-time" v-if="!courseInfo.is_share">学习人数：{{ courseInfo.buy_num + courseInfo.learn_num > 9999 ? ((courseInfo.buy_num+ courseInfo.learn_num) / 10000 ).toFixed(2) + 'W': (courseInfo.buy_num+ courseInfo.learn_num) }}人学习<span style="color: #D0D6DE;margin: 0 8px">|</span></div>
            <div class="table-item-time">课程时长：{{ courseInfo.length ? $carbon.formatSeconds(courseInfo.length) : 0 }}</div>
          </div>
          <div>
            <el-tag
              style="margin-right: 20px;background:rgba(255, 165, 9, 0.1);border: unset;color: #FFA509;border-radius: 10px;padding: 0 10px;"
              v-for="(item,index) in courseInfo.tags"
              :key="index"
              size="mini"
              type="success"
              effect="dark">
              {{ item.name }}
            </el-tag>
          </div>
        </div>
      </div>

      <el-tab-pane label="课时管理" class="first" name="chapters"></el-tab-pane>
      <el-tab-pane label="课程媒体" class="two" name="media"></el-tab-pane>
      <el-tab-pane label="资料管理" class="three" name="materials">
        <!--        搜索查询-->
        <div class="search space-between">
          <div class="flex-nowrap">
            <el-select v-model="options" placeholder="请选择" class="search-type" >
              <el-option
                  v-for="item in optionsData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-input v-model="keywords" placeholder="请输入查询条件" class="search-input"></el-input>
            <el-button type="primary" class="search-btn" @click="searchSubmit">查询</el-button>
            <el-button class="search-btn reset" @click="resetFun">重置</el-button>
          </div>
          <el-button type="warning" class="f-r search-add" @click="addMaterials" v-if="!courseInfo.is_share">新建资料</el-button>
        </div>

        <div class="total space-between">
          <div class="total-left">共有 <span class="total-left-text">{{materialsInfo.meta ? materialsInfo.meta.total : 0}}</span> 条数据</div>
<!--          <el-button type="text" class="total-right">批量删除</el-button>-->
        </div>

        <!--        表格-->
        <div v-if="materialsInfo.data && materialsInfo.data.length">
          <el-table
              class="table"
              ref="multipleTable"
              :data="materialsInfo.data"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              :header-cell-style="{background:'#F6F8F9',color: '#1C1F21',fontWeight:'400'}"
              border>
            <el-table-column
                prop="title"
                label="名称"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                label="类型"
                width="130">
              <template slot-scope="scope">
                <span>{{ scope.row.type | typeFun }}</span>
              </template>
            </el-table-column>
            <el-table-column
                label="大小"
                width="130">
              <template slot-scope="scope">
                <span>{{ (((scope.row.size)/1024)/1024).toFixed(2) }}M</span>
              </template>
            </el-table-column>
<!--            <el-table-column-->
<!--                prop="download_num"-->
<!--                label="下载次数"-->
<!--                width="130">-->

<!--            </el-table-column>-->
            <el-table-column
                prop="updated_at"
                label="更新时间"
                width="170">

            </el-table-column>
            <el-table-column
                label="操作"
                align="center"
                width="120"
                v-if="!courseInfo.is_share">
              <template slot-scope="scope">
                <el-button  type="text" size="small" class="table-edit" @click="editMaterials(scope.row)">编辑</el-button>
                <span style="color: #D0D6DE;margin: 0 8px">|</span><el-button  type="text" size="small" class="table-edit" @click="delMaterials(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <empty-prompt v-else></empty-prompt>


        <el-pagination
            v-if="materialsInfo.meta && materialsInfo.meta.last_page > 1"
            class="page"
            background
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout=" prev, pager, next,total, jumper"
            :total="materialsInfo.meta ? materialsInfo.meta.total : 0">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane :label="courseInfo.comment_count ? (courseInfo.comment_count > 99 ?  '留言 '+'99+' : '留言 '+courseInfo.comment_count) : '留言 '+ 0 " class="four" name="interact"  v-if="!courseInfo.is_share"></el-tab-pane>
      <el-tab-pane label="学员管理" class="five" name="student"  v-if="!courseInfo.is_share"></el-tab-pane>
    </el-tabs>

    <!--  新增资料管理  -->
    <el-dialog
        class="dialog"
        :title="createdType === 'create' ? '新增资料' : '编辑资料'"
        :visible.sync="isDialogMaterials"
        v-if="isDialogMaterials"
        top="calc( 50vh - 150px )"
        width="540px"
        :destroy-on-close="true"
         >
      <el-form label-position="right" label-width="100px" :model="materialFrom" :rules="materialRules" ref="materialFrom"
               class="form">
        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="materialFrom.type">
            <el-radio :label="item.value" v-for="(item, index) in typeData" :key="index">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input placeholder="请输入标题"  maxlength="50" v-model="materialFrom.title"></el-input>
        </el-form-item>
        <el-form-item label="上传资料" prop="file_name" >
          <upload-file @successUrl="imgUrl" @successFile="fileData" :folder="'course/materials'" :limit="1" :multiple="false" :default_value="new Array(materialFrom.link)" :file_info_data="new Array({name:materialFrom.file_name,size:materialFrom.size})" :upload_type="'file'" :accept_type="'.zip,.csv,.docx,.doc,.pdf,.ppt,.xlsx,image/jpg,image/jpeg,image/png'"></upload-file>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input type="textarea" placeholder="请输入描述" maxlength="150" v-model="materialFrom.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="closeMaterials">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" v-if="create_status" @click="MaterialsCreate">确 定</el-button>
        <el-button class="form-operation-item" round type="primary" :loading="true" v-else >提交中</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadFile from "components/uploadFile";
import EmptyPrompt from "components/EmptyPrompt";
export default {
  name: "Materials",
  data() {
    return {
      loading:true,
      activeName: 'materials',
      uuid: '',
      courseInfo: {}, // 获取课程信息
      page: 1,
      pageSize: 20,
      options: '',  // 选项
      optionsData: [
        {
          label: '标题',
          value: 'title'
        }
      ],
      keywords: '' ,  // 关键词
      materialsInfo: {},  // 资料列表
      multipleSelection: [],  // 多选
      createdType: 'create',  // 弹框显示类型
      isDialogMaterials: false,  // 弹框
      typeData: [
        {
          label: '课件',
          value: 'courseware'
        },
        {
          label: '笔记',
          value: 'notes'
        },
        {
          label: '软件',
          value: 'software'
        },
        {
          label: '文献',
          value: 'literature'
        },
        {
          label: '其他',
          value: 'other'
        }
      ], // 类型选择数据
      materialFrom: {
        type: 'courseware',
        title: '',
        file_name: '',
        link: '',
        size: '',
        description: '',
      },  // 上传资料弹框
      materialRules: {
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        file_name: [
          { required: true, message: '请选择资料', trigger: 'blur' }
        ]
      },  // 上传资料验证
      create_status: true,
      current_material: {}, // 当前点击
    }
  },
  created() {
    if (this.$route.params.uuid) {
      this.uuid = this.$route.params.uuid
      this.queryFun()
      this.getCourses()
      this.getMaterials()
    }
  },
  methods: {
    // 获取课程信息
    getCourses() {
      let url = this.$api.Courses + '/' + this.uuid;
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.courseInfo = res.data.data;
          this.loading = false
        }
      }).catch(err =>{
        this.loading = false
      })
    },
    // 获取资料列表
    getMaterials() {
      let url= this.$api.Courses + '/' + this.uuid + '/' + this.$api.Materials + `?page=${this.page}&pageSize=${this.pageSize}&options=${this.options}&keywords=${this.keywords}`;
      this.$http.get(url, true).then(res => {
        if(res.data.success) {
          this.materialsInfo= res.data.data
        }
      })
    },
    // 查询
    searchSubmit() {
      this.page= 1;
      this.linkFun();
      this.getMaterials()
    },
    // 重置
    resetFun() {
      this.options= ''
      this.keywords= ''
      this.searchSubmit()
    },
    // 取消上传
    closeMaterials() {
      this.isDialogMaterials= false
    },
    // 新增资料
    addMaterials() {
      this.materialFrom = {
        type:'courseware'
      }
      this.createdType= 'create'
      this.isDialogMaterials= true
    },

    // 确定提交
    MaterialsCreate() {
      this.$refs.materialFrom.validate((valid) => {
        if (valid) {
          if(!this.create_status) {
            return  false
          }
          this.create_status= false
          this.createFun()
        } else {
          return false;
        }
      });
    },
    // 创建资料提交方法
    createFun() {
      if(this.createdType== 'create') {
        let url= this.$api.Courses + '/' + this.uuid + '/' + this.$api.Materials;
        this.$http.post(url, this.materialFrom, true).then(res => {
          if(res.data.success) {
            this.$message.success('提交成功')
            this.isDialogMaterials= false
            this.getMaterials()
            setTimeout(()=>{
              this.create_status= true
            }, 1000)
            this.$refs.materialFrom.resetFields();
          }else {
            this.create_status= true
            this.$message.error(res.data.message)
          }
        }).catch(error => {
          this.create_status= true
          this.$message.error(error.response.data.message)
        })
      }else {
        let url= this.$api.Courses + '/' + this.uuid + '/' + this.$api.Materials + '/' + this.current_material.id;
        this.$http.put(url, this.materialFrom, true).then(res => {
          if(res.data.success) {
            this.$message.success('编辑成功')
            this.isDialogMaterials= false
            this.getMaterials()
            setTimeout(()=>{
              this.create_status= true
            }, 1000)
            this.$refs.materialFrom.resetFields();
          }else {
            this.create_status= true
            this.$message.error(res.data.message)
          }
        }).catch(error => {
          this.create_status= true
          this.$message.error(error.response.data.message)
        })
      }

    },
    // 改变路由参数
    linkFun() {
      this.$router.push({path: `/courses/class/${this.uuid}/materials`,query: {
          options: this.options,
          keywords: this.keywords,
          level: this.level,
          status: this.status,
          page: this.page,
          pageSize: this.pageSize
        }})
    },
    // 参数赋值
    queryFun() {
      let query= this.$route.query;
      if(query) {
        if(query.options) {
          this.options= query.options;
        }
        if(query.keywords) {
          this.keywords= query.keywords;
        }
        if(query.level) {
          this.level= query.level;
        }
        if(query.status) {
          this.status= query.status;
        }
        if(query.page) {
          this.page= parseInt(query.page);
        }
        if(query.pageSize) {
          this.pageSize= parseInt(query.pageSize);
        }
      }
    },
    // 多选值
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 资料上传
    imgUrl(data) {
      if(data.length) {
        this.$set(this.materialFrom, 'link', data[0])
        this.$refs.materialFrom.clearValidate('link')
      }
    },
    // 上传文件详情
    fileData(data) {
      if(data.length) {
        this.materialFrom.file_name= data[0].name
        this.materialFrom.size= data[0].size
      }else{
        this.materialFrom.file_name= ''
        this.materialFrom.size= ''
      }

    },
    // 编辑资料
    editMaterials(data) {
      this.createdType= 'edit'
      this.isDialogMaterials= true
      this.current_material= data;
      let {type, title, file_name, link, size, description} = data
      this.materialFrom= {
        type ,
        title ,
        file_name ,
        link ,
        size ,
        description
      }
    },
    // 删除资料
    delMaterials(data) {
      this.$confirm('这是您操作比较重要的数据，你确定要删除该资料么？, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url= this.$api.Courses+ '/'+ this.uuid + '/' + this.$api.Materials + '/'+ data.id;
        this.$http.del(url, true).then(res => {
          if(res.data.success) {
            this.getMaterials()
            this.$message.success('删除成功')
          }else {
            this.$message.error(res.data.message)
          }
        }).catch(error=>{
          this.$message.error(error.response.data.message)
        })
      }).catch(() => {});
    },
    //分页
    handleCurrentChange(val) {
      this.page= val
      this.linkFun()
      this.getMaterials()
    },
    // tab切换想像
    handleClick(tab, event) {
      if(tab.name === 'chapters') {
        this.$router.push({path: `/courses/class/${this.uuid}/chapters`})
      }else if(tab.name === 'media') {
        this.$router.push({path: `/courses/class/${this.uuid}/media`})
      }else if(tab.name === 'materials') {
        this.$router.push({path: `/courses/class/${this.uuid}/materials`})
      }else if(tab.name === 'interact') {
        this.$router.push({path: `/courses/class/${this.uuid}/interact`})
      }else if(tab.name === 'student') {
        this.$router.push({path: `/courses/class/${this.uuid}/student`})
      }else {
        this.$router.push({path: `/courses/courses`})
      }
    }
  },
  components: {
    uploadFile,
    EmptyPrompt
  },
  filters: {
    typeFun(value) {
      let type= '';
      switch (value){
        case 'courseware':
          type= '课件'
        break;
        case 'notes':
          type= '笔记'
          break;
        case 'software':
          type= '软件'
          break;
        case 'literature':
          type= '文献'
          break;
        case 'other':
          type= '其他'
          break;
      }
      return type
    }
  }
}
</script>

<style lang="less" scoped>
@import "~assets/less/courses/classHour/index.less";
.el-tab-pane{
  min-height: 528px;
}
</style>
